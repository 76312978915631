<template>
  <Modal
    :title="(isEditing ? 'Update' : 'New') + ' Transaction Range'"
    :show="show"
    :class="'modal-allowance-type'"
    :submitLabel="isEditing ? 'Update' : 'Save'"
    :loadingSubmit="loadingSubmit"
    :isCancel="true"
    :isSubmit="true"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="columns">
                <div class="column">
                  <ValidationProvider
                    name="Min Amount"
                    v-slot="{ errors }"
                    rules="required|numeric|min_value:1"
                  >
                    <b-field
                      label="Transaction Range"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <currency-input
                        v-model="formData.min_amount"
                        currency="IDR"
                        locale="id"
                        class="currency-input"
                        :allow-negative="false"
                        :precision="{ min: 0, max: 0 }"
                        :distraction-free="false"
                      ></currency-input>
                    </b-field>
                  </ValidationProvider>
                </div>

                <h5 class="mt-6">to</h5>

                <div class="column">
                  <ValidationProvider
                    name="Max Amount"
                    v-slot="{ errors }"
                    rules="numeric|min_value:1"
                  >
                    <b-field
                      label=" "
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                      class="range-input"
                    >
                      <currency-input
                        v-model="formData.max_amount"
                        currency="IDR"
                        locale="id"
                        class="currency-input"
                        :allow-negative="false"
                        :precision="{ min: 0, max: 0 }"
                        :distraction-free="false"
                        :disabled="isMaxUnlimited"
                      />
                    </b-field>
                  </ValidationProvider>

                  <ValidationProvider name="duration" v-slot="{ errors }">
                    <b-field
                      label=" "
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-checkbox
                        @change.native="onUnlimited()"
                        :value="isMaxUnlimited"
                      >
                        Unlimited
                      </b-checkbox>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div v-if="!isEditing" class="column is-12 section-radio-tooltip">
              <h6 class="mb-0">PIC</h6>
              <div
                v-for="(approver, counter) in approvers"
                v-bind:key="counter"
              >
                <div class="pic-form-container">
                  <div
                    class="column pl-0 ml-0"
                    :class="counter === 0 ? 'is-12' : 'is-11'"
                  >
                    <b-autocomplete
                      placeholder="Search User"
                      :check-infinite-scroll="true"
                      icon-right="account"
                      v-model.lazy="formData.fullName"
                      :custom-formatter="(option) => option.fullName"
                      v-model="searchUserData"
                      :data="listUser"
                      field="fullName"
                      @typing="getUserList"
                      @select="(event) => selectUser(event, counter)"
                      @infinite-scroll="getMoreUserList"
                    >
                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-left">
                            <div v-if="props.option.profilePictureUrl">
                              <img
                                class="profile-picture"
                                width="32"
                                :src="props.option.profilePictureUrl"
                              />
                            </div>
                            <div v-else>
                              <img
                                class="profile-picture"
                                v-if="props.option.gender === 'F'"
                                width="32"
                                src="/images/default-profile-picture-female.png"
                              />

                              <img
                                class="profile-picture"
                                v-else
                                width="32"
                                src="/images/default-profile-picture-male.png"
                              />
                            </div>
                          </div>
                          <div class="media-content">
                            {{ props.option.fullName }}
                            <br />
                            <small>
                              {{
                                props.option.positionName
                                  ? props.option.positionName
                                  : '-'
                              }}
                            </small>
                          </div>
                        </div>
                      </template>
                    </b-autocomplete>
                  </div>
                  <div v-if="counter !== 0" class="column is-1">
                    <a @click="deleteApprover(counter)">
                      <b-icon type="is-danger" icon="delete" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="add-user-btn-container">
                <b-button
                  class="is-command ml-0"
                  icon-left="plus"
                  @click.native="addApprover()"
                >
                  Add User
                </b-button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  computed: {
    searchUserData: function () {
      return this.searchUser
    },
  },

  data() {
    return {
      approvers: [
        {
          selectedPic: '',
          level: '',
        },
      ],
    }
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    searchUser: {
      type: String,
      default: '',
    },
    listUser: {
      type: Array,
      default: null,
    },
    isMaxUnlimited: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    addApprover() {
      this.approvers.push({
        userId: '',
        level: '',
      })
    },

    deleteApprover(counter) {
      this.approvers.splice(counter, 1)
    },
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit hide modal component.
     */
    onUnlimited() {
      this.$emit('onUnlimited')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     */
    selectUser(event, index) {
      this.$emit('selectUser', { option: event, index })
    },

    /**
     * Emit fetch more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },
  },
}
</script>
