<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Reimbursement Setting">
      <template #page-header>
        <h2 class="page-title">Request Management</h2>
        <r-menu-tab :menu-data="menuHeaders" />
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click.native="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-reimbursement-setting" class="table-timeoff-type">
          <b-table
            :data="reimbursementTypeList"
            :per-page="perPage"
            :loading="isReimbursementTypeListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>
              <b-table-column
                field="name"
                label="Category"
                v-slot="props"
                sortable
                width="67%"
              >
                <span class="is-capitalize">{{ props.row.name }}</span>
              </b-table-column>
              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon is-clickable"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="deletionWarning(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>

          <div class="mt-5">
            <ReimbursementApprovers
              :isFetchingPic="isFetchingPic"
              :listPic="listPic"
              :reimbursementCategories="reimbursementCategories"
              @getPicList="getPicList($event)"
              @getMorePicList="getMorePicList"
              @openModalType="openModalType($event)"
              @deleteFinalApp="deleteFinalApp($event)"
              @addApprover="addApprover($event)"
              @deleteApprover="deleteApprover($event)"
              @selectApprover="selectApprover($event)"
              @savePicReimbursement="savePicReimbursement($event)"
            />

            <div class="level">
              <div class="level-left">
                <b-button
                  :disabled="is_unlimited_exists"
                  tag="button"
                  class="is-command mt-3"
                  icon-left="plus"
                  @click.native="openModalType()"
                >
                  New Transaction Range
                </b-button>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <h4>Final Approver</h4>

            <div class="is-justify-content-flex-start mt-3">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form
                  @submit="handleSubmit(submitPicReimbursement)"
                  class="is-label-14px-normal form-timeoff-type"
                >
                  <div class="columns">
                    <div class="column is-4">
                      <ValidationProvider
                        name="Final Approver"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-autocomplete
                            v-model="reimbursementPic"
                            :data="listPic"
                            placeholder="Search PIC"
                            field="fullName"
                            :check-infinite-scroll="true"
                            icon-right="account"
                            @typing="getPicList"
                            @select="selectPicReimbursement"
                            @infinite-scroll="getMorePicList"
                            :loading="isFetchingPic"
                          >
                            <template slot-scope="props">
                              <div class="media">
                                <div class="media-left">
                                  <div v-if="props.option.profilePictureUrl">
                                    <img
                                      class="profile-picture"
                                      width="32"
                                      :src="props.option.profilePictureUrl"
                                    />
                                  </div>
                                  <div v-else>
                                    <img
                                      class="profile-picture"
                                      v-if="props.option.gender === 'F'"
                                      width="32"
                                      src="/images/default-profile-picture-female.png"
                                    />

                                    <img
                                      class="profile-picture"
                                      v-else
                                      width="32"
                                      src="/images/default-profile-picture-male.png"
                                    />
                                  </div>
                                </div>
                                <div class="media-content">
                                  {{ props.option.fullName }}
                                  <br />
                                  <small>
                                    {{
                                      props.option.positionName
                                        ? props.option.positionName
                                        : '-'
                                    }}
                                  </small>
                                </div>
                              </div>
                            </template>
                          </b-autocomplete>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-1">
                      <div class="level">
                        <div class="level-left">
                          <div class="buttons">
                            <b-button
                              @click="submitPicReimbursement()"
                              type="is-primary"
                              :loading="isSubmitting"
                            >
                              Save
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>

        <ReimbursementCategoryModal
          :show="isModalOpen"
          :formData="data"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          @hide="closeModalCreate"
          @submit="submit"
        />

        <TransactionRangeModal
          :show="isModalOpenType"
          :formData="reimbursForm"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :searchUser="searchUser"
          :listUser="listUser"
          :isMaxUnlimited="isMaxUnlimited"
          @hide="resetFormFinalApproval"
          @submit="
            isEditing
              ? editReimbursementCategory()
              : SubmitReimbursementCategory()
          "
          @getUserList="getUserList"
          @selectUser="selectUser"
          @getMoreUserList="getMoreUserList"
          @onUnlimited="onUnlimited"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast, generateMessageList, showErrorToast } from '@/services/util'
import ReimbursementCategoryModal from './ReimbursementCategoryModal.vue'
import ApiService from '../../../services/common/api.service'
import debounce from 'lodash/debounce'
import TransactionRangeModal from './TransactionRangeModal.vue'
import ReimbursementApprovers from './ReimbursementApprovers.vue'

export default {
  data() {
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      menuHeaders: null,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      search: '',
      showDetailIcon: true,
      isReimbursementTypeListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isModalOpenType: false,
      isEditing: false,
      data: {
        id: '',
        name: null,
        companyId: null,
        companyName: null,
      },
      lastItem: null,
      searchUser: '',
      listUser: [],
      selectedUser: '',
      lastPageUserList: 1,
      pageUserList: 1,
      reimbursement: null,
      is_unlimited_exists: null,
      reimbursForm: {
        min_amount: null,
        max_amount: null,
        approvers: [{ user_id: null, level: null, fullName: '' }],
      },
      isSubmitting: null,

      // PIC Reimbursement
      reimbursementPic: null,
      listPic: [],
      selectedPic: '',
      isFetchingPic: false,
      lastPagePicList: 1,
      pagePicList: 1,
      isMaxUnlimited: false,
      reimbursementCategories: [],
    }
  },
  computed: {
    ...mapGetters({
      reimbursementTypeList: 'reimbursement/getReimbursementTypeData',
      currentUser: 'user/getUserInfo',
    }),
    isUnlimited: {
      get() {
        if (this.reimbursForm.max_amount !== '') {
          return false
        }
        return true
      },
    },
  },
  methods: {
    ...mapActions({
      actionLoadReimbursementTypeList:
        'reimbursement/fetchReimbursementTypeData',
      saveReimbursementType: 'reimbursement/saveReimbursementTypeData',
      saveReimbursementPic: 'reimbursement/saveReimbursementPic',
      editReimbursementType: 'reimbursement/editReimbursementTypeData',
      deleteReimbursementType: 'reimbursement/deleteReimbursementTypeData',
      editReimbursFinalApproval: 'reimbursement/editReimbursementFinalApproval',
      addReimbursFinalApproval: 'reimbursement/saveReimbursementFinalApproval',
      deleteReimbursFinalApproval: 'reimbursement/deleteReimbursFinalApproval',
    }),
    ...mapMutations({
      setReimbursementType: 'reimbursement/setReimbursementTypeData',
    }),

    /**
     * Open sweet alert when deleting data
     * @param {integer} id - selected id data
     */
    deleteFinalApp(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteReimbursFinalApproval(id)
            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.getReimbursFinalApproval()
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },

    /**
     * Make reimburse maximum amount null
     */
    onUnlimited() {
      this.reimbursForm.max_amount = null
      this.isMaxUnlimited = !this.isMaxUnlimited
    },

    /**
     * Set Currency format
     * @param {integer} money - currency amount
     */
    formatCurrency(money) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      }).format(money)
    },

    /**
     * Reset form when modal is closed
     */
    resetFormFinalApproval() {
      this.searchUser = ''
      this.reimbursForm = {
        min_amount: null,
        max_amount: null,
        approvers: [{ user_id: null, level: null, fullName: '' }],
      }
      this.isModalOpenType = false
      this.isEditing = false
    },

    /**
     * Create new reimbursement range
     */
    async SubmitReimbursementCategory() {
      this.isSubmitting = true
      try {
        if (this.reimbursForm.approvers.some((element) => !element.user_id)) {
          showToast('the PIC field is required', 'is-danger', 'is-top')
          this.isSubmitting = false
          return
        }
        await this.addReimbursFinalApproval(this.reimbursForm)
        showToast('Data is successfully added!', 'is-success', 'is-top')
        this.isSubmitting = false

        this.resetFormFinalApproval()

        this.getReimbursFinalApproval()
      } catch (error) {
        // showToast(error.response.data.message, 'is-danger', 'is-top')
        showErrorToast(error)
        // console.log(error)
        this.isSubmitting = false
      }
      this.isSubmitting = false
    },

    /**
     * Create new reimbursement range
     */
    async editReimbursementCategory() {
      this.isSubmitting = true
      try {
        await this.editReimbursFinalApproval(this.reimbursForm)

        showToast('Data is successfully added!', 'is-success', 'is-top')
        this.isSubmitting = false

        this.resetFormFinalApproval()

        this.getReimbursFinalApproval()
      } catch (error) {
        showToast(error.response.data.message, 'is-danger', 'is-top')
        console.log(error)
        this.isSubmitting = false
      }
      this.isSubmitting = false
    },

    /**
     * Add approver item
     * @param {integer} index - index array
     */
    addApprover(index) {
      this.reimbursementCategories[index].approvers.push({
        user_id: null,
        level: index + 1,
      })
    },

    /**
     * Delete approver item
     * @param {object} data
     */
    deleteApprover(data) {
      const { index, counter } = data
      this.reimbursementCategories[index].approvers.splice(counter, 1)
    },

    /**
     * Close Final Approval Modal
     */
    closeModalFinalApproval() {
      this.resetFormFinalApproval()
    },

    /**
     * submit Reimbursement Category data
     */
    async onSubmit() {
      this.isSubmitting = true
      try {
        if (!this.isEditing) {
          if (!this.reimbursement.length) {
            this.lastItem = 0
          } else {
            this.lastItem = this.reimbursement.at(-1)
          }
          const initDataAdded = {
            max_amount: parseInt(this.reimbursForm.max_amount),
            min_amount: parseInt(this.reimbursForm.min_amount),
            user_id: this.reimbursForm.id,
          }
          if (this.lastItem.maxAmount <= initDataAdded.min_amount) {
            initDataAdded.min_amount++
            initDataAdded.max_amount++
            await this.addReimbursFinalApproval(initDataAdded)
            this.getReimbursFinalApproval()
            this.resetFormFinalApproval()
            this.isModalOpenType = false
            showToast('Data is successfully added!', 'is-success', 'is-top')
          } else {
            await this.addReimbursFinalApproval(initDataAdded)
            this.getReimbursFinalApproval()
            this.resetFormFinalApproval()
            this.isModalOpenType = false
            showToast('Data is successfully added!', 'is-success', 'is-top')
          }
        } else {
          const initData = {
            id: this.reimbursForm.id,
            max_amount: parseInt(this.reimbursForm.max_amount),
            min_amount: parseInt(this.reimbursForm.min_amount),
            user_id: this.reimbursForm.user_id,
          }
          await this.editReimbursFinalApproval(initData)
          showToast('Data is successfully edited!', 'is-success', 'is-top')
          this.getReimbursFinalApproval()
          this.resetFormFinalApproval()
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
      this.isEditing = false
      this.resetFormFinalApproval()
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(data) {
      const { option, index } = data

      this.reimbursForm.approvers[index] = {
        user_id: option.id,
        level: index + 1,
      }
    },

    /**
     * Select User for PIC Reimbursement
     * @param {Object} option - selected user object (optional)
     */
    selectPicReimbursement(option) {
      if (option) {
        this.selectedPic = option
        this.reimbursementPic = option.fullName
      }
    },

    /**
     * Select User for PIC Reimbursement
     * @param {Object} option - selected user object (optional)
     */
    async savePicReimbursement(data) {
      const ids = data.approvers.map((item) => item.user_id)

      const isDuplicate = ids.some((item, idx) => ids.indexOf(item) !== idx)

      const isEmpty = ids.some((item) => ids.indexOf(item) === null)

      if (isDuplicate) {
        showToast('The approvers must not be the same', 'is-danger', 'is-top')
        return
      }

      if (isEmpty) {
        showToast('Ada yang kosong!', 'is-danger', 'is-top')
        return
      }

      try {
        data.approvers.forEach((element) => {
          delete element.fullName
        })

        await this.editReimbursFinalApproval(data)

        showToast('Data is successfully edited!', 'is-success', 'is-top')

        this.getReimbursFinalApproval()
      } catch (error) {
        console.log(error)
        showToast(error.response.data.message, 'is-danger', 'is-top')
      }
    },

    /**
     * Select Approver
     * @param {Object} option - selected user object (optional)
     */
    selectApprover(data) {
      const { event, counter, index } = data

      if (event)
        this.reimbursementCategories[index].approvers[counter] = {
          user_id: event.id,
          level: counter + 1,
          fullName: event.fullName,
        }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUser = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get PIC list
     * @return {Object} listPic - Pic List
     */
    getPicList: debounce(function (name) {
      if (this.selectedPic !== name) {
        this.selectedPic = name
        this.listPic = []
        this.pagePicList = 1
        this.lastPagePicList = 1
      }
      if (!name && !name.length) {
        this.listPic = []
        this.pagePicList = 1
        this.lastPagePicList = 1
        return
      }
      if (this.pagePicList > this.lastPagePicList) {
        return
      }
      this.isFetchingPic = true
      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedPic,
        page: this.pagePicList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listPic.push(item))
          this.pagePicList++
          this.lastPagePicList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingPic = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    /**
     * Get more PIC list when auto complete scrolled
     * @return {Object} listPic - Pic List
     */
    getMorePicList: debounce(function () {
      this.getPicList(this.selectedPic)
    }, 250),

    /**
     * Failsafe for level 3 headers
     */
    generateMenuData() {
      this.menuHeaders = [
        {
          title: 'Time Off Category',
          path: '/settings/request-management/time-off-category',
        },
        {
          title: 'Reimbursement Setting',
          path: '/settings/request-management/reimbursement-category',
        },
      ]
    },

    /**
     * Load reimbursement type data
     */
    async loadReimbursementTypeData() {
      this.isReimbursementTypeListLoading = true
      try {
        const response = await this.actionLoadReimbursementTypeList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          usage: 'table',
        })
        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementTypeListLoading = false
    },

    /**
     * Load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadReimbursementTypeData()
        this.isLoadMore = false
      }
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadReimbursementTypeData()
    },

    /**
     * Open create modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          name: row.name,
        }
      }
      this.isModalOpen = true
    },

    /**
     * Open type modal. If row present,
     * set form to include data from selected table row
     * else,
     * set default min amount based on the last transaction range's max amount
     * @param {integer} row - table row index (optional)
     */
    openModalType(row) {
      if (row) {
        this.isEditing = true
        this.reimbursForm = {
          id: row.id,
          min_amount: row.minAmount,
          max_amount: row.maxAmount,
          user_id: row.userId,
        }
      } else {
        let lastTransactionRange = this.reimbursementCategories.slice(-1)
        let lastTransactionRangeMaxAmount = lastTransactionRange[0]
          ? lastTransactionRange[0].maxAmount
          : 0
        this.reimbursForm.min_amount = lastTransactionRangeMaxAmount + 1
      }
      this.isModalOpenType = true
    },

    /**
     * Close Create Modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * reset form when modal is closed
     */
    resetForm() {
      this.data = {
        id: '',
        name: null,
      }
    },

    /**
     * reset table state to default
     */
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setReimbursementType([])
      await this.loadReimbursementTypeData()
    },

    /**
     * Edit Final Approval method
     * @param {Object} row - selected final approval data
     */
    async editFinalApproval(row) {
      this.isSubmitting = true
      try {
        const initData = {
          min_amount: row.minAmount,
          max_amount: row.maxAmount,
          user_id: this.reimbursForm.id,
          id: row.id,
        }
        await this.editReimbursFinalApproval(initData)
        this.getReimbursFinalApproval()
        this.resetFormFinalApproval()
        showToast('Data is successfully edited!', 'is-success', 'is-top')
      } catch (e) {
        showToast(e.response.message, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
    },

    /**
     * Submit reimbursement category form to database
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()
      try {
        if (!this.isEditing) {
          form.append('name', this.data.name)
          await this.saveReimbursementType(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } else {
          await this.editReimbursementType(this.data)
          showToast('Data is successfully edited!', 'is-success', 'is-top')
          this.isEditing = false
        }
        this.isModalOpen = false
        this.resetForm()
      } catch (e) {
        const errorList = generateMessageList(e.response.data.message)
        showToast(errorList, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
    },

    /**
     * Submit/Assign PIC Reimbursement
     */
    async submitPicReimbursement() {
      this.isSubmitting = true
      let form = new FormData()
      if (!this.selectedPic.id && !this.selectedPic.userId) {
        showToast('Final Approver cannot be empty!', 'is-danger', 'is-top')
        this.isSubmitting = false
        return
      }
      try {
        form.append('user_id', this.selectedPic.id || this.selectedPic.userId)
        form.append('module_type', 9)
        form.append('company_id', this.currentUser.companyId)
        await this.saveReimbursementPic(form)
        showToast('Data is successfully updated!', 'is-success', 'is-top')
      } catch (e) {
        showErrorToast(e)
      }
      this.isSubmitting = false
    },

    /**
     * Open warning sweet alert when deleting a data
     * @param {integer} id - selected ID from database
     */
    deletionWarning(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteReimbursementType(id)
            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },

    /**
     * Get Reimbursement Final Approval user
     */
    fetchReimbursementPic() {
      // get
      ApiService.get(
        '/api/space-roketin/final-approval-request/module?module_type=9'
      )
        .then((response) => {
          this.reimbursementPic = response.data.userName
          this.selectedPic = response.data
        })
        .catch((error) => {
          throw error
        })
    },

    /**
     * Get Reimbursement Final Approval user
     */
    getReimbursFinalApproval() {
      // get
      ApiService.get(
        '/api/space-roketin/settings/reimbursement-final-approval?sort_field=min_amount&sort_order=asc&per_page=20'
      )
        .then((response) => {
          this.reimbursement = response.data.data
          const arr = response.data.data

          const result = arr.map((item) => ({
            id: item.id,
            maxAmount: item.maxAmount,
            minAmount: item.minAmount,
            approvers: item.approvers.map((approver) => ({
              user_id: approver.user.userId,
              level: approver.level,
              fullName: approver.user.fullName,
            })),
          }))
          this.reimbursementCategories = result
          this.is_unlimited_exists = response.data.meta.isUnlimitedExists
        })
        .catch((error) => {
          throw error
        })
    },
  },

  async mounted() {
    this.setReimbursementType([])
    this.loadReimbursementTypeData()
    this.fetchReimbursementPic()
    this.generateMenuData()
    this.getReimbursFinalApproval()
  },
  components: {
    ReimbursementCategoryModal,
    TransactionRangeModal,
    ReimbursementApprovers,
  },
}
</script>
