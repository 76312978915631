<template>
  <div>
    <h4>Approval Transaction</h4>
    <div>
      <div
        v-for="(category, index) in reimbursementCategories"
        v-bind:key="index"
        class="range-card"
      >
        <div class="amount-range-container">
          <div>
            <p>
              {{ formatCurrency(category.minAmount) }} -
              {{
                category.maxAmount === null
                  ? 'Unlimited'
                  : formatCurrency(category.maxAmount)
              }}
            </p>
          </div>
          <b-icon
            @click.native="openModalType(category)"
            type="is-primary"
            icon="pencil"
            custom-size="mdi-17px"
            class="employment-edit-icon"
          />
        </div>
        <div
          v-for="(approver, counter) in category.approvers"
          v-bind:key="counter"
        >
          <div class="pic-form-container">
            <div class="column is-8 pl-0 ml-0">
              <b-autocomplete
                :data="listPic"
                placeholder="Search User"
                :check-infinite-scroll="true"
                icon-right="account"
                @typing="getPicList"
                @select="(event) => selectApprover({ event, index, counter })"
                @infinite-scroll="getMorePicList"
                v-model.lazy="approver.fullName"
                :custom-formatter="(option) => option.fullName"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-left">
                      <div v-if="props.option.profilePictureUrl">
                        <img
                          class="profile-picture"
                          width="32"
                          :src="props.option.profilePictureUrl"
                        />
                      </div>
                      <div v-else>
                        <img
                          class="profile-picture"
                          v-if="props.option.gender === 'F'"
                          width="32"
                          src="/images/default-profile-picture-female.png"
                        />

                        <img
                          class="profile-picture"
                          v-else
                          width="32"
                          src="/images/default-profile-picture-male.png"
                        />
                      </div>
                    </div>
                    <div class="media-content">
                      {{ props.option.fullName }}
                      <br />
                      <small>
                        {{
                          props.option.positionName
                            ? props.option.positionName
                            : '-'
                        }}
                      </small>
                    </div>
                  </div>
                </template>
              </b-autocomplete>
            </div>
            <div v-if="counter !== 0" class="column is-1">
              <a @click="deleteApprover(index, counter)">
                <b-icon type="is-danger" icon="delete" />
              </a>
            </div>
          </div>
        </div>
        <div class="add-user-btn-container">
          <b-button
            class="is-command ml-0"
            icon-left="plus"
            @click.native="addApprover(index)"
          >
            Add User
          </b-button>
          <!-- <p class="reimbursement-error-note">All field must be filled</p> -->
          <div class="btn-group mt-3">
            <b-button
              type="is-primary"
              class="ml-0"
              @click="savePicReimbursement(category)"
            >
              Save
            </b-button>
            <b-button class="is-command" @click="deleteFinalApp(category.id)">
              Delete
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  props: {
    msg: String,
    isFetchingPic: Boolean,
    listPic: Array,
    reimbursementCategories: Array,
  },
  methods: {
    /**
     * Set Currency format
     * @param {integer} money - currency amount
     */
    formatCurrency(money) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      }).format(money)
    },

    /**
     * Set Currency format
     * @param {integer} money - currency amount
     */
    openModalType(event) {
      this.$emit('openModalType', event)
    },

    /**
     * Set Currency format
     * @param {integer} money - currency amount
     */
    deleteFinalApp(event) {
      this.$emit('deleteFinalApp', event)
    },

    /**
     * Emit Submit modal.
     */
    getPicList(event) {
      this.$emit('getPicList', event)
    },
    /**
     * Emit Submit modal.
     */
    getMorePicList() {
      this.$emit('getMorePicList')
    },

    /**
     * Emit add approver
     */
    addApprover(event) {
      this.$emit('addApprover', event)
    },

    /**
     * Emit delete approver
     */
    deleteApprover(index, counter) {
      this.$emit('deleteApprover', { index, counter })
    },

    /**
     * Emit Submit modal.
     */
    savePicReimbursement(item) {
      this.$emit('savePicReimbursement', item)
    },

    /**
     * Emit select approver.
     */
    selectApprover(item) {
      const { event, counter, index } = item
      if (event) this.$emit('selectApprover', { event, index, counter })
    },
  },
}
</script>
