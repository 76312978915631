<template>
  <div>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are you sure?"
      v-if="show"
      @hide="hide"
      :is-modal="true"
      key="modalKey"
      :class="modalClass"
    >
      <template #page-content>
        <h3 class="modal-title has-text-weight-black">
          {{ title }}
          <span class="office-pointer-click is-pulled-right">
            <b-icon icon="close" size="is-small" @click.native="hide()" />
          </span>
        </h3>
        <div class="max-height-75">
          <slot name="form"></slot>
        </div>

        <div class="columns container mt-1" v-if="isSubmit">
          <div class="column" v-if="isCancel">
            <b-button type="is-light" expanded @click="hide()">Cancel</b-button>
          </div>

          <div class="column" v-if="isSubmit">
            <b-button
              type="is-primary"
              expanded
              :loading="loadingSubmit"
              @click="submit"
            >
              {{ submitLabel || 'Submit' }}
            </b-button>
          </div>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    modalClass: {
      type: String,
      default: '',
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: '',
    },
    isCancel: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Hide modal component.
     */
    hide() {
      this.$emit('hide')
    },
    /**
     * Hide modal component.
     */
    submit() {
      this.$emit('onSubmit')
    },
  },
}
</script>

<style></style>
